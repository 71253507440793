@import "../media-queries";
//OVERLAYS
.cdk-overlay-backdrop {
  z-index: 990;
}

.cdk-overlay-container {
  .cdk-global-overlay-wrapper {

    .cdk-overlay-pane {
      app-toast-content[class*='top-snackbar-'] {
        position: relative;
        margin: 0.5rem;
        left: calc(var(--toolbar-left-max-width) / 2);
        top: calc(var(--toolbar-height) + env(safe-area-inset-top));

        &.shorten {
          height: auto;
          min-height: 96px;
        }

        @include for-size(tablet-portrait) {
          top: var(--toolbar-low-height);
          left: 0;
        }

        @include for-size(phone) {
          top: 0;
          left: 0;
        }
      }
    }

    @include for-size(phone) {
      max-height: calc(100% - var(--toolbar-height) - var(--fast-access-menu-height) - env(safe-area-inset-top) - env(safe-area-inset-bottom));
      margin-top: calc(var(--toolbar-height) + env(safe-area-inset-top)) !important;
    }

    @include for-size(mini-phone) {
      max-height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
      margin-top: env(safe-area-inset-top) !important;
    }
  }

  .cdk-overlay-backdrop {
    background: rgba(255, 255, 255, 0.6);

    app-toast-content {
      margin: 0.5rem;
    }
  }
}

@include for-size(phone) {
  .cdk-overlay-pane.is-import-dialog,
  .cdk-overlay-pane.is-status-dialog,
  .cdk-overlay-pane.is-custom-dialog {
    width: auto;
    max-width: 100% !important;

    .mat-mdc-dialog-container {
      max-height: 600px !important;
      width: 100% !important;
      height: 100% !important;
    }
  }

  .cdk-overlay-pane.is-import-dialog,
  .cdk-overlay-pane.is-status-dialog {
    width: 100vw;
    max-width: 370px !important;
    max-height: 600px;
    height: 100%;
  }
}

.dark-theme {
  .cdk-overlay-container {
    .cdk-overlay-backdrop {
      background: rgba(0, 0, 0, 0.8)
    }
  }
}

//CARDS
.mat-mdc-card {
  border-radius: 0;
  background-color: var(--background-color-default);
}

//PANELS
.mat-expansion-panel.slim-body-panel {
  .mat-content {
    overflow: unset;
  }

  .mat-expansion-panel-body {
    padding: 0 var(--default-content-padding);
  }
}

.mat-expansion-panel.slim-body-panel .mat-content {
  overflow: hidden;
}


//DIALOGS
.mat-mdc-dialog-container {
  display: inline-block;
  border: 1px solid var(--action-card-border-color);
  margin: var(--action-card-margin);
  --mdc-dialog-container-elevation-shadow: var(--box-shadow-dialog-default) !important;
  --mdc-dialog-container-color: var(--background-color-action-card);

  .mdc-dialog__surface {
    border-radius: var(--default-round-corner-radius) !important;
  }

  .mat-mdc-dialog-title {
    font-size: 24px !important;
    line-height: 26px !important;
    color: var(--text-color-action-card-shade);
  }

  .mdc-dialog__content {
    color: var(--text-color-action-card-shade) !important;
  }

  .mat-mdc-dialog-actions {
    display: flex;
    justify-content: flex-end;
    padding-top: var(--default-content-padding);
    padding-right: var(--default-content-padding);

    button,
    a {
      border-radius: var(--default-round-corner-radius) !important;

      &[color="primary"] {
        background-color: var(--color-red);
      }
    }
  }

  @include for-size(phone) {
    margin: 0;
  }
}

app-simple-message-dialog {
  .mat-dialog-actions {
    @include for-size(phone) {
      display: grid;
      gap: var(--default-content-padding);
      grid-template-columns: 1fr;
      button {
        margin: 0 !important;
      }
    }
  }
}

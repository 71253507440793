/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill::first-line,
input:is(:invalid, [aria-invalid=true]):is(:-webkit-autofill, :-webkit-autofill:focus),
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0 solid !important;
  -webkit-text-fill-color: var(--text-color) !important;
  -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
  transition: background-color 60000s 0s, color 60000s 0s !important;
}

.full-width-input {
  width: 100%;
}

.mat-mdc-input-element {
  font-weight: 300 !important;
}

.mat-mdc-raised-button,
.mat-mdc-unelevated-button,
.mat-mdc-raised-button:hover,
.mat-mdc-unelevated-button:hover,
.mat-mdc-raised-button:active,
.mat-mdc-unelevated-button:active {
  .mat-mdc-button-persistent-ripple::before {
    content: '';
    display: none;
  }
}

.mat-form-field-appearance-fill {
  .mdc-text-field,
  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }

  .mat-mdc-form-field-focus-overlay {
    display: none;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: transparent !important;
  }
}

.login-form-field {
  line-height: 16px;
  font-size: 16px;

  .mat-mdc-text-field-wrapper {
    padding-bottom: 0 !important;
  }

  .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-form-field-icon-suffix mat-icon {
    padding: 0 !important;
  }

  .mat-mdc-form-field-flex {
    background: var(--background-color-shade);
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 0.75em !important;
  }

  .dark-theme & .mat-mdc-form-field-flex {
    background: var(--color-brand-white);
    padding: 0.75em !important;
  }

  .dark-theme & .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: var(--base-text-color-primary-light);
  }

  .dark-theme & .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: var(--base-text-color-secondary-light);
  }

  .mat-mdc-form-field-infix {
    border: none;
    border-radius: 5px;
    min-height: 0;
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  &.mat-form-field-invalid {
    .mat-mdc-form-field-flex {
      border: 1px solid var(--color-primary);
    }
  }

  &.white-form-field {
    .mat-mdc-form-field-flex {
      background: var(--color-white);
    }

    .mat-mdc-input-element {
      color: var(--color-black) !important;
    }
  }
}

.outlined-form-field {
  width: 100%;
  line-height: 19px;
  --outline-size: 22px;

  .mat-mdc-text-field-wrapper {
    border-radius: 0;
  }

  .mat-mdc-form-field-flex {
    gap: var(--default-content-padding);
  }

  .mat-mdc-form-field-infix {
    min-height: auto !important;
  }

  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-suffix {
    height: var(--outline-size);
    width: var(--outline-size);
    padding-right: 0;
    padding-left: 0;
    overflow: hidden;
  }

  app-custom-icon {
    height: var(--outline-size) !important;
    width: var(--outline-size) !important;
    background-size: var(--outline-size) var(--outline-size) !important;
  }

  .mat-icon-button,
  mat-icon {
    height: auto;
    width: auto;
    cursor: pointer;
  }

  .mdc-line-ripple {
    display: none;
  }
}

/*.mat-form-field-appearance-fill {
  .mat-mdc-form-field-subscript-wrapper,
  .mat-mdc-text-field-wrapper {
    padding-bottom: 0 !important;
  }

  .mat-mdc-form-field-underline {
    display: none;
  }

  .mat-mdc-form-field-infix {
    border: none;
    background: var(--background-color-shade);
    border-radius: 5px;
  }

  .mat-mdc-form-field-flex {
    background: var(--background-color-shade);
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 0.75em !important;
  }

  &.mat-form-field-invalid {
    .mat-mdc-form-field-flex {
      border: 1px solid var(--color-primary);
    }
  }
}*/

.filter-input {
  width: 100%;

  .mat-mdc-form-field-flex {
    align-items: center;
    border: 1px solid rgba(125, 185, 225, 0.1);
    border-radius: 15px;
    background: rgba(125, 185, 225, 0.1);
    padding: 0 8px !important;

    .mat-mdc-form-field-icon-suffix .mat-mdc-icon-button {
      width: auto;
      height: auto;

      .mat-icon {
        opacity: 0.5;
      }
    }

    .mat-mdc-form-field-icon-prefix .mat-icon {
      opacity: 0.5;
      padding: 0;
    }

    .mat-mdc-form-field-infix {
      padding: 1px 0 !important;
      margin: 3px 0 4px 0;
      background: none;
      width: 100%;
      border: 0;
      min-height: auto;

      input[type="text"] {
        margin-top: 0;
        width: 100%;
        height: auto;
      }
    }
  }

  .mat-mdc-form-field-subscript-wrapper,
  .mdc-line-ripple {
    display: none;
  }
}

.mat-mdc-checkbox {
  .mat-mdc-checkbox-touch-target,
  .mdc-checkbox__ripple,
  input[type="checkbox"],
  .mdc-checkbox__background,
  .mdc-checkbox {
    width: 14px !important;
    height: 14px !important;
    padding: 0;
    margin: 0;
  }

  .mdc-checkbox__ripple {
    display: none;
  }

  .mdc-checkbox__background {
    display: inline-block;
    top: 0 !important;
    left: 0 !important;
    border: 1px solid var(--mdc-checkbox-border-color);
  }

  dark-theme & .mdc-checkbox__background {
    border: 1px solid var(--mdc-checkbox-border-color);
  }

  &.mat-mdc-checkbox-checked .mdc-checkbox__background {
    border-color: var(--color-blue);
  }

  .mdc-label {
    line-height: 26px;
    white-space: nowrap;
  }

  &.fw-500 {
    .mdc-label {
      font-weight: 500;
    }
  }
}

// Buttons
.mat-icon-button {
  width: 30px;
  height: 30px;
  padding: 0;
  background: none;
  border: 0;
}

.dark-theme .mat-mdc-icon-button.mat-mdc-button-base,
.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 8px;
  --mdc-icon-button-state-layer-size: 40px;
}

.mat-mdc-icon-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  line-height: initial !important;

  &[disabled="true"] {
    cursor: not-allowed !important;
    opacity: 0.3;
  }
}

.mat-mdc-outlined-button,
.mat-mdc-raised-button,
.mat-mdc-unelevated-button {
  box-shadow: none !important;
  border-radius: 0 !important;
  line-height: 44px !important;
  min-width: 44px;
  min-height: 44px;

  &.center {
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      justify-content: center;
      @include for-size(phone) {
        gap: 0.5rem;
      }
    }
  }
}

.mat-mdc-unelevated-button {
  &[color="accent"],
  &.mat-accent {
    color: var(--color-white);
    --mdc-filled-button-label-text-color: var(--color-white);
    background-color: var(--color-blue);
    --mdc-filled-button-container-color: var(--color-blue);
  }
}

.mat-mdc-outlined-button {
  border: 2px solid currentColor !important;
  min-width: 40px;
  min-height: 40px;
  line-height: 40px !important;

  &[color="primary"] {
    border-color: var(--color-primary);
  }

  &.mat-accent,
  &[color="accent"] {
    color: var(--color-blue);
    --mdc-outlined-button-label-text-color: var(--color-blue);
    border-color: var(--color-blue);
  }
}

.dark-theme .mat-mdc-outlined-button.mat-accent {
  color: var(--color-white);
  border-color: #383838 !important;
}

.no-border-form-fields {
  .mat-mdc-form-field {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline {
      display: none;
    }

    .mat-mdc-form-field-flex {
      align-items: center;
      background: var(--background-color-action-button);
      padding: 0.5rem;
      border: 1px solid var(--select-border-color-dragging);

      .mat-mdc-form-field-infix {
        padding: 0;
        border: 0;
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-label-wrapper {
          top: 0;
          padding: 0;

          label {
            top: 0;
          }
        }
      }
    }
  }
}

.mat-mdc-form-field.no-bottom-padding .mat-mdc-text-field-wrapper {
  padding-bottom: 0 !important;
}

.mat-mdc-form-field.no-top-padding .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
  align-items: center;
  padding-top: 0;
}


.mat-mdc-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: var(--color-blue);
}

.bg-white.mat-form-field-appearance-fill .mat-mdc-form-field-flex,
.bg-white.mat-form-field-appearance-fill .mat-mdc-form-field-infix {
  background: var(--action-card-list-color);
}

.action-card {
  .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
    background: var(--action-card-list-color);
    padding: calc(var(--default-content-padding) / 2) var(--default-content-padding) !important;
    border: 1px solid var(--action-card-list-border-color);
  }

  .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-infix {
    background: transparent;
    border-radius: 0;
  }
}

.mat-mdc-icon-button.fab-button {
  border-radius: 50%;
  padding: 0;
  --mdc-icon-button-state-layer-size: var(--action-menu-button-size);
}

app-history-button .mat-mdc-icon-button,
.mat-mdc-icon-button.history-back-button {
  font-weight: 700;
  padding: 0 !important;

  mat-icon {
    width: auto;
    height: auto;
    font-size: 40px;
  }
}

.tutorial-helper-backdrop {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.tutorial-helper-active-description {
  text-align: center;
  background-color: #1464F0;
  position: absolute;
  font-size: 14px;
  padding: var(--default-content-padding);
  width: 220px;
  z-index: 9998;
  transition-property: transform;
  will-change: transform;
  transform: translateX(0) translateY(0);
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.075, 0.75, 0.225, 1);
}

#tutorial-helper-active-description-title {
  color: var(--color-white);
  overflow-y: auto;
  font-weight: bold;
  padding-bottom: calc(var(--default-content-padding) / 2);
}

#tutorial-helper-active-description-text {
  color: var(--color-white);
  overflow-y: auto;
  height: 75%;
}

.tutorial-helper-active {
  position: absolute;
  transition-duration: 500ms;
  transition-property: transform;
  will-change: transform;
  transition-timing-function: cubic-bezier(0.075, 0.75, 0.225, 1);
}

.tutorial-helper-active-element {
  overflow: hidden;
  position: relative;
}

.tutorial-helper-arrow {
  position: absolute;
  width: 0;
  height: 0;
  z-index: 9997;
  transition-property: transform;
  will-change: transform;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.075, 0.75, 0.225, 1);
}

.tutorial-helper-arrow-up {
  border-left: 24px solid transparent;
  border-right: 24px solid transparent;
  border-bottom: 24px solid #1464F0;
}

.tutorial-helper-arrow-down {
  border-left: 24px solid transparent;
  border-right: 24px solid transparent;
  border-top: 24px solid #1464F0;
}

.tutorial-helper-arrow-left {
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  border-right: 24px solid #1464F0;
}

.tutorial-helper-arrow-right {
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  border-left: 24px solid #1464F0;
}

.button {
    background: var(--button-background-color, transparent);
    border: var(--button-border-size, 1px) var(--button-border-style, solid) var(--button-border-color, var(--color-brand-black));
    border-radius: var(--button-border-radius, 20px);
    color: var(--button-color, var(--color-brand-black));
    cursor: pointer;
    line-height: 1;
    padding: var(--button-vertical-padding, 0.875em) var(--button-horizontal-padding, 2em);
    font-family: inherit;
    font-size: var(--button-font-size, 1em);
    font-weight: bold;
}

@use '@angular/material' as mat;

@include mat.core();
$font-name: Inter;
// Typography
$typographyDefault: mat.m2-define-typography-config(
        $font-family: $font-name,
        $headline-5: mat.m2-define-typography-level(28px, 40px, 500, $font-name),
        $headline-6: mat.m2-define-typography-level(24px, 32px, 500, $font-name),
        $subtitle-1: mat.m2-define-typography-level(16px, 24px, 500, $font-name),
        $subtitle-2: mat.m2-define-typography-level(24px, 32px, 500, $font-name),
        $body-1: mat.m2-define-typography-level(16px, 24px, 300),
        $body-2: mat.m2-define-typography-level(16px, 26px, 300),
        $button: mat.m2-define-typography-level(16px, 24px, 500),
);

@include for-size(desktop) {
  $typographyDefault: mat.m2-define-typography-config(
          $font-family: $font-name,
          $headline-5: mat.m2-define-typography-level(28px, 40px, 600, $font-name),
          $headline-6: mat.m2-define-typography-level(22px, 32px, 600, $font-name),
          $subtitle-1: mat.m2-define-typography-level(12px, 16px, 600, $font-name),
          $subtitle-2: mat.m2-define-typography-level(12px, 16px, 600, $font-name),
          $body-1: mat.m2-define-typography-level(16px, 24px, 500),
          $body-2: mat.m2-define-typography-level(16px, 26px, 500),
          $button: mat.m2-define-typography-level(16px, 24px, 600),
  );
}

@include mat.all-component-typographies($typographyDefault);

input, textarea, select {
  font-family: inherit;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

@mixin for-size($size) {
  @if $size == mini-phone {
    @media (max-device-width: 350px) {
      @content;
    }
  } @else if $size == phone {
    @media (max-device-width: 599px) and (orientation: portrait),
    (max-device-height: 599px) and (orientation: landscape) {
      @content;
    }
  } @else if $size == tablet-portrait {
    @media (min-device-width: 600px) and (max-device-width: 1025px) and (orientation: portrait) {
      @content;
    }
  } @else if $size == tablet {
    @media (min-device-width: 600px) and (max-device-width: 1025px) and (orientation: portrait),
    (min-device-height: 600px) and (max-device-height: 1025px) and (orientation: landscape) {
      @content;
    }
  } @else if $size == desktop {
    @media (min-device-width: 1026px) and (orientation: portrait),
    (min-device-height: 1026px) and (orientation: landscape) {
      @content;
    }
  }
}

.mat-mdc-tooltip {
  .mdc-tooltip__surface {
    margin: 14px;
    max-width: 250px;
    padding: 6px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: normal;

    --mdc-plain-tooltip-supporting-text-size: 14px;
    --mdc-plain-tooltip-supporting-text-color: #fff;
    --mdc-plain-tooltip-container-color: #616161;
    --mdc-plain-tooltip-supporting-text-weight: 300;
  }

  &.system-trashed,
  &.checked-out {
    .mdc-tooltip__surface {
      --mdc-plain-tooltip-container-color: var(--color-red);
    }
  }

  &.system-message,
  &.checked-out-self {
    .mdc-tooltip__surface {
      --mdc-plain-tooltip-container-color: var(--color-yellow);
    }
  }

  &.version .mdc-tooltip__surface {
    --mdc-plain-tooltip-container-color: var(--color-green);
  }

  &.version-archived .mdc-tooltip__surface {
    --mdc-plain-tooltip-container-color: var(--color-blue);
  }

  &.action-menu-tooltip .mdc-tooltip__surface {
    border: 1px solid var(--action-menu-tooltip-border-color);
    border-radius: 50rem;
    box-shadow: 1px 1px 5px var(--action-menu-tooltip-shadow-color);
    padding: var(--action-menu-tooltip-padding);
    --mdc-plain-tooltip-container-color: var(--background-color-default);
    --mdc-plain-tooltip-supporting-text-color: var(--text-color);
    --mdc-plain-tooltip-supporting-text-weight: inherit;
    --mdc-plain-tooltip-supporting-text-font: inherit;
  }
}

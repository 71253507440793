:root {

    /********************************************************
     * BASE BRAND COLORS BASED ON THE MARKETING PLAYBOOK
     ********************************************************/

    /* PRIMARY COLORS */
    --color-brand-red: #eb413c;
    --color-brand-blue: #1464f0;
    --color-brand-white: #ffffff;
    --color-brand-light-grey: #eeeeee;
    --color-brand-black: #444444;

    /* SECONDARY COLORS */
    --color-brand-orange: #ffc30f;
    --color-brand-purple: #c84bc8;
    --color-brand-green: #32aa5a;

    /* TRANSPARENT COLORS */
    --color-brand-transparent-blue: #7db4e1;
    --color-brand-transparent-blue-20: #97c3e7;
    --color-brand-transparent-blue-40: #b1d2ed;
    --color-brand-transparent-blue-60: #cbe1f3;
    --color-brand-transparent-blue-80: #e5f0f9;
    --color-brand-transparent-blue-100: #f7fafd;

    /********************************************************
     * SPACING
     ********************************************************/
    --base-spacing-1: 0.25em;
    --base-spacing-2: calc(var(--base-spacing-1) * 2);
    --base-spacing-3: calc(var(--base-spacing-2) * 2);

    /********************************************************
    * Z-INDEX
    ********************************************************/
    --z-index-loading-spinner: 2;
    --z-index-login-server-switch-wrapper: 3;

    /********************************************************
    * TEXT
    ********************************************************/
    --base-text-color-primary-light: #333333;
    --base-text-color-secondary-light: #8c8c8c;
    --base-text-color-primary-dark: #eaeaea;
    --base-text-color-secondary-dark: #bcbcbc;

    /********************************************************
    * BORDERS
    ********************************************************/
    --base-border-radius: 27px;

    /********************************************************
    * LINKS
    ********************************************************/
    --base-link-color-light: var(--color-brand-blue);
    --base-link-color-dark: #89b3fa;

    /* @TODO: text-underline-offset */

    /********************************************************
     * SECTIONS
     ********************************************************/
    --base-section-border-color-light: #cccccc;
    --base-section-border-color-dark: #2a2a2a;
    --base-section-border-width: 1px;
    --base-section-spacing: var(--base-spacing-3);
}

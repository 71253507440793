// mat list override
.cdk-virtual-scroll-orientation-vertical {
  visibility: hidden;

  .cdk-virtual-scroll-content-wrapper {
    padding: 0;
    width: 100%;
    min-width: auto !important;
    min-height: 100%;
  }
}

.mat-mdc-list {
  padding-top: 0 !important;

  .mat-mdc-subheader {
    padding: 0;
  }

  .mat-mdc-list-item {
    height: 60px !important;

    .mdc-list-item__content {
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    .mdc-list-item__primary-text {
      --mdc-list-list-item-supporting-text-weight: normal;
      --mdc-list-list-item-supporting-text-line-height: 24px;
      margin-bottom: 0 !important;

      &:before,
      &:after {
        display: none !important;
      }
    }

    .mdc-list-item__secondary-text {
      --mdc-list-list-item-supporting-text-weight: normal;
      --mdc-list-list-item-supporting-text-line-height: 24px;
      --mdc-list-list-item-supporting-text-size: 80%;
      color: var(--text-color-shade);

      &:before {
        content: none;
      }
    }

    &.mdc-list-item--with-leading-icon {
      .mdc-list-item__start {
        align-self: center;
        margin: 0 !important;
      }

      .mdc-list-item__content {
        align-self: center;
        padding-left: var(--default-content-padding);
      }
    }
  }

  .mat-mdc-list-item .mat-mdc-list-item-icon.has-custom-icon,
  .mat-mdc-list-option .mat-mdc-list-icon.has-custom-icon {
    width: 30px;
    height: 30px;
  }

  .mat-mdc-list-item.mat-list-item-with-avatar,
  .mat-list-option.mat-list-item-with-avatar {
    height: 60px !important;
  }

  .mat-mdc-list-item.mat-2-line,
  .mat-mdc-list-option.mat-2-line {
    height: 60px !important;
  }

  .mat-mdc-list-item,
  .item-wrapper a .mat-mdc-list-item {
    cursor: pointer;

    .mat-mdc-list-item-line {
      width: calc(100% - var(--default-content-padding));
    }
  }

  .mat-mdc-list-item-icon.mat-list-icon-right {
    order: 10;

    &.mat-icon-auto-size mat-icon {
      width: auto;
      height: auto;
    }
  }
}

//TODO: GEHOERT DAS HIER REIN?
.left-section-content {
  .list-smallPreview {
    .items {
      width: 100% !important;
      padding: calc(var(--default-content-padding) / 2) 0 !important;
    }
  }

  .list-smallPreview,
  .list-largePreview {
    .items {
      grid-gap: calc(var(--default-content-padding) * 2) 0;
    }
  }
}

.action-card .mdc-list-item__content button:last-of-type {
  margin-right: calc(var(--default-content-padding) / 2);
}

app-list-base-card .mat-list-loading.show,
app-list-base-card .filter.show {
  border-bottom: 0 !important;
}

@use '@angular/material' as mat;
@import './styles/media-queries';

@include mat.core();
@import 'styles/material/material.typography';

@at-root {
  :root {
    --mdc-checkbox-selected-hover-state-layer-opacity: 1.0;
  }
}

$md-app: (
        50 : #fde8e8,
        100 : #f9c6c5,
        200 : #f5a09e,
        300 : #f17a77,
        400 : #ee5e59,
        500 : #eb413c,
        600 : #e93b36,
        700 : #e5322e,
        800 : #e22a27,
        900 : #dd1c1a,
        A100 : #ffffff,
        A200 : #ffdddd,
        A400 : #ffabaa,
        A700 : #ff9290,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$md-accent: (
        50 : #eff7fb,
        100 : #d8eaf6,
        200 : #bedcf0,
        300 : #a4ceea,
        400 : #91c4e6,
        500 : #7db9e1,
        600 : #75b2dd,
        700 : #6aaad9,
        800 : #60a2d5,
        900 : #4d93cd,
        A100 : #ffffff,
        A200 : #f6fbff,
        A400 : #c3e3ff,
        A700 : #a9d7ff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #000000,
                800 : #000000,
                900 : #000000,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$md-warning: (
        50 : #fffaed,
        100 : #fff4d1,
        200 : #ffecb3,
        300 : #ffe494,
        400 : #ffdf7d,
        500 : #ffd966,
        600 : #ffd55e,
        700 : #ffcf53,
        800 : #ffca49,
        900 : #ffc038,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #fff5e1,
        A700 : #ffedc8,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #000000,
                800 : #000000,
                900 : #000000,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$app-primary: (primary: mat.m2-define-palette($md-app));
// The warn palette is optional (defaults to red).
$app-warn: (warn: mat.m2-define-palette($md-warning));
$app-accent: (accent: mat.m2-define-palette($md-accent));
$theme: mat.m2-define-light-theme($app-primary, $app-accent, $app-warn);

@at-root {
  :root {
    --text-color: rgba(0, 0, 0, 0.87);
    --text-color-light: rgba(0, 0, 0, 0.87);
    --text-color-shade: rgb(153, 153, 153);
    --text-color-action-card-shade: #444444;
    --text-color-version-shade: #c9c9c9;
    --text-color-grey: #888888;
    --background-color-default: #fff;
    --background-color-shade: #f5f5f5;
    --background-color-document-preview: #ededed;
    --background-color-action-card: rgba(250, 250, 250, 1);
    --background-color-action-card-transparent: #ffffff;
    --background-color-action-button: #ffffff;
    --background-color-badge: #bdbdbd;
    --color-document-background: #f8f8f8;
    --color-primary: #{mat.get-theme-color($app-primary, primary)};
    --color-accent: #{mat.get-theme-color($app-accent, accent)};
    --color-warn: #{mat.get-theme-color($app-warn, warn)};
    --toolbar-background-color: #fff;
    --toolbar-text-color: var(--color-blue);
    --action-card-border-color: transparent;
    --page-counter-background: #d1d1d1;
    --page-counter-opacity: .9;
    --page-counter-color: #8c8c8c;
    --select-border-color: var(--color-blue);
    --select-border-color-dragging: #8d8d8d;

    --fast-access-background: whitesmoke; // default bgcolor of mat-toolbar
    --toolbar-border-color: #ccc;

    --loader-gif-icon: url(/assets/images/loading-spinner.svg);
    --background-color-loader: rgba(255, 255, 255, .5);

    --color-document-list-selected: #e0f4ff;
    --color-document-list-changed-marked: rgba(255, 195, 15, 0.2);
    --color-document-list-changed-marked-opaque: #fff3d5;
    --color-document-list-deleted-marked: rgba(235, 65, 60, 0.2);
    --color-document-list-deleted-marked-opaque: #fbdcdc;

    --action-menu-tooltip-border-color: #ccc;
    --action-menu-tooltip-shadow-color: rgba(204, 204, 204, 0.2);
    --action-menu-tooltip-padding: 0.5rem 1rem;
    --input-background: #f4f4f4;
    --login-shadow-color: rgba(0, 0, 0, 0.2);
    --solution-store-header-background: #eeeeee;
    --solution-store-header-icon-border: #cccccc;
    --solution-store-recommended-bgcolor: #d3f0d2;
    --solution-store-tip-badge-bg-color: #32aa5a;
    --solution-store-icon-bg-color: #ffffff;
    --action-card-list-border-color: #ccc;
    --action-card-list-color: #fff;

    --mdc-checkbox-border-color: var(--color-dark-grey);
    --mdc-checkbox-selected-checkmark-color: var(--color-white);
    --mdc-checkbox-selected-hover-state-layer-color: var(--color-blue);
    --mdc-checkbox-selected-focus-icon-color: var(--color-blue);
    --mdc-checkbox-selected-pressed-icon-color: var(--color-blue);
    --mdc-checkbox-selected-icon-color: var(--color-blue);
    --mdc-dialog-container-color: #FAFAFAFF;
  }
}

@include mat.private-check-duplicate-theme-styles($theme, 'angular-material-theme') {
  @include mat.core-theme($theme);
  @include mat.card-theme($theme);
  @include mat.progress-bar-theme($theme);
  @include mat.tooltip-theme($theme);
  @include mat.form-field-theme($theme);
  @include mat.input-theme($theme);
  @include mat.select-theme($theme);
  @include mat.autocomplete-theme($theme);
  @include mat.dialog-theme($theme);
  @include mat.chips-theme($theme);
  @include mat.slide-toggle-theme($theme);
  @include mat.radio-theme($theme);
  @include mat.slider-theme($theme);
  @include mat.menu-theme($theme);
  @include mat.list-theme($theme);
  @include mat.paginator-theme($theme);
  @include mat.tabs-theme($theme);
  @include mat.checkbox-theme($theme);
  @include mat.button-theme($theme);
  @include mat.icon-button-theme($theme);
  @include mat.fab-theme($theme);
  @include mat.snack-bar-theme($theme);
  @include mat.table-theme($theme);
  @include mat.progress-spinner-theme($theme);
  @include mat.badge-theme($theme);
  @include mat.bottom-sheet-theme($theme);
  @include mat.button-toggle-theme($theme);
  @include mat.divider-theme($theme);
  @include mat.expansion-theme($theme);
  @include mat.grid-list-theme($theme);
  @include mat.icon-theme($theme);
  @include mat.sidenav-theme($theme);
  @include mat.stepper-theme($theme);
  @include mat.sort-theme($theme);
  @include mat.toolbar-theme($theme);
  @include mat.tree-theme($theme);
}

.dark-theme {
  $md-app-dark: (
          50 : #fde8e8,
          100 : #f9c6c5,
          200 : #f5a09e,
          300 : #f17a77,
          400 : #ee5e59,
          500 : #eb413c,
          600 : #e93b36,
          700 : #e5322e,
          800 : #e22a27,
          900 : #dd1c1a,
          A100 : #ffffff,
          A200 : #ffdddd,
          A400 : #ffabaa,
          A700 : #ff9290,
          contrast: (
                  50 : #000000,
                  100 : #000000,
                  200 : #000000,
                  300 : #000000,
                  400 : #000000,
                  500 : #ffffff,
                  600 : #ffffff,
                  700 : #ffffff,
                  800 : #ffffff,
                  900 : #ffffff,
                  A100 : #000000,
                  A200 : #000000,
                  A400 : #000000,
                  A700 : #000000,
          )
  );

  $md-accent-dark: (
          50 : #e7e7e7,
          100 : #c3c3c3,
          200 : #9c9c9c,
          300 : #747474,
          400 : #565656,
          500 : #383838,
          600 : #323232,
          700 : #2b2b2b,
          800 : #242424,
          900 : #171717,
          A100 : #ef7171,
          A200 : #ea4343,
          A400 : #fa0000,
          A700 : #e00000,
          contrast: (
                  50 : #000000,
                  100 : #000000,
                  200 : #000000,
                  300 : #ffffff,
                  400 : #ffffff,
                  500 : #ffffff,
                  600 : #ffffff,
                  700 : #ffffff,
                  800 : #ffffff,
                  900 : #ffffff,
                  A100 : #000000,
                  A200 : #ffffff,
                  A400 : #ffffff,
                  A700 : #ffffff,
          )
  );

  $md-warning-dark: (
          50 : #fffaed,
          100 : #fff4d1,
          200 : #ffecb3,
          300 : #ffe494,
          400 : #ffdf7d,
          500 : #ffd966,
          600 : #ffd55e,
          700 : #ffcf53,
          800 : #ffca49,
          900 : #ffc038,
          A100 : #ffffff,
          A200 : #ffffff,
          A400 : #fff5e1,
          A700 : #ffedc8,
          contrast: (
                  50 : #000000,
                  100 : #000000,
                  200 : #000000,
                  300 : #000000,
                  400 : #000000,
                  500 : #000000,
                  600 : #000000,
                  700 : #000000,
                  800 : #000000,
                  900 : #000000,
                  A100 : #000000,
                  A200 : #000000,
                  A400 : #000000,
                  A700 : #000000,
          )
  );

  $app-primary-dark: (primary: mat.m2-define-palette($md-app-dark));
  // The warn palette is optional (defaults to red).
  $app-warn-dark: (warn: mat.m2-define-palette($md-warning-dark));
  $app-accent-dark: (accent: mat.m2-define-palette($md-accent-dark));
  $theme-dark: mat.m2-define-dark-theme($app-primary-dark, $app-accent-dark, $app-warn-dark);

  // override root variables in dark mode
  --text-color: #eaeaea;
  --text-color-light: rgba(0, 0, 0, 0.87);
  --text-color-shade: #c1c1c1;
  --text-color-action-card-shade: #ffffff;
  --text-color-version-shade: #969696;
  --text-color-grey: var(--text-color);
  --background-color-default: #000;
  --background-color-shade: #717171;
  --background-color-document-preview: #717171;
  --background-color-action-card: rgba(40, 40, 40, 1);
  --background-color-action-card-transparent: rgba(40, 40, 40, .95);
  --background-color-action-button: transparent;
  --background-color-badge: #505050;
  --color-document-background: #000;
  --color-primary: #{mat.get-theme-color($app-primary-dark, primary)};
  --color-accent: #{mat.get-theme-color($app-accent-dark, accent)};
  --color-warn: #{mat.get-theme-color($app-warn, warn)};
  --toolbar-background-color: #000;
  --toolbar-text-color: #fff;
  --page-counter-background: #8d8d8d;
  --page-counter-opacity: .4;
  --page-counter-color: #ffffff;
  --select-border-color: var(--color-blue);
  --select-border-color-dragging: #8d8d8d;

  --fast-access-background: #{mat.m2-get-color-from-palette($app-accent-dark)};
  --toolbar-border-color: #2a2a2a;
  --loader-gif-icon: url(/assets/images/loading-spinner.svg);
  --background-color-loader: rgba(255, 255, 255, .5);

  --color-document-list-selected: #454545;
  --color-document-list-changed-marked: rgba(255, 195, 15, 0.2);
  --color-document-list-changed-marked-opaque: #fff3d5;
  --color-document-list-deleted-marked: rgba(235, 65, 60, 0.2);
  --color-document-list-deleted-marked-opaque: #fbdcdc;

  --action-menu-tooltip-border-color: #eaeaea;
  --action-menu-tooltip-shadow-color: rgba(255, 255, 255, 0.2);
  --input-background: #717171;
  --input-color: #f4f4f4;
  --login-shadow-color: rgba(204, 204, 204, 0.2);
  --solution-store-header-background: rgba(238, 238, 238, 0.2);
  --solution-store-header-icon-border: #666;
  --solution-store-recommended-bgcolor: rgba(240, 240, 240, 0.2);
  --solution-store-tip-badge-bg-color: #505050;
  --solution-store-icon-bg-color: #000;

  --action-card-list-border-color: #000;
  --action-card-list-color: var(--input-background);

  --mdc-checkbox-border-color: var(--color-white);
  --mdc-checkbox-selected-checkmark-color: var(--color-white);
  --mdc-checkbox-selected-hover-state-layer-color: var(--color-blue);
  --mdc-checkbox-selected-focus-icon-color: var(--color-blue);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-blue);
  --mdc-checkbox-selected-icon-color: var(--color-blue);
  --mdc-dialog-container-color: #282828;

  @include mat.private-check-duplicate-theme-styles($theme-dark, 'angular-material-theme') {
    @include mat.core-theme($theme-dark);
    @include mat.card-theme($theme-dark);
    @include mat.progress-bar-theme($theme-dark);
    @include mat.tooltip-theme($theme-dark);
    @include mat.form-field-theme($theme-dark);
    @include mat.input-theme($theme-dark);
    @include mat.select-theme($theme-dark);
    @include mat.autocomplete-theme($theme-dark);
    @include mat.dialog-theme($theme-dark);
    @include mat.chips-theme($theme-dark);
    @include mat.slide-toggle-theme($theme-dark);
    @include mat.radio-theme($theme-dark);
    @include mat.slider-theme($theme-dark);
    @include mat.menu-theme($theme-dark);
    @include mat.list-theme($theme-dark);
    @include mat.paginator-theme($theme-dark);
    @include mat.tabs-theme($theme-dark);
    @include mat.checkbox-theme($theme-dark);
    @include mat.button-theme($theme-dark);
    @include mat.icon-button-theme($theme-dark);
    @include mat.fab-theme($theme-dark);
    @include mat.snack-bar-theme($theme-dark);
    @include mat.table-theme($theme-dark);
    @include mat.progress-spinner-theme($theme-dark);
    @include mat.badge-theme($theme-dark);
    @include mat.bottom-sheet-theme($theme-dark);
    @include mat.button-toggle-theme($theme-dark);
    @include mat.divider-theme($theme-dark);
    @include mat.expansion-theme($theme-dark);
    @include mat.grid-list-theme($theme-dark);
    @include mat.icon-theme($theme-dark);
    @include mat.sidenav-theme($theme-dark);
    @include mat.stepper-theme($theme-dark);
    @include mat.sort-theme($theme-dark);
    @include mat.toolbar-theme($theme-dark);
    @include mat.tree-theme($theme-dark);
  }
}

@import "styles/material/material.overlays.dialogs";
@import "styles/material/material.forms";
@import "styles/material/material.lists";
@import "styles/material/material.toolbar";
@import "styles/material/material.tooltips";

.cdk-global-scrollblock {
  overflow-y: auto;
}

/* You can add global styles to this file, and also import other style files */

@import "app/styles/base.css";
@import "app/styles/components/button.css";
@import "@fontsource/inter/300.css";
@import "@fontsource/inter/400.css";
@import "@fontsource/inter/500.css";
@import "@fontsource/inter/600.css";
@import 'app/styles/media-queries';

@function filter-invert() {
  @return #{ "invert()" };
}

:root {
  --mq-max-width-smartphone: 767px;
  --fast-access-menu-height: 72px;
  --toolbar-left-max-width: 368px;
  --toolbar-height: 64px;
  --toolbar-low-height: 34px;
  --toolbar-tabs-height: 64px;
  --default-content-padding: 1rem;
  --half-default-content-padding: calc(var(--default-content-padding) / 2);
  --toolbar-low-height-button: 20px;
  --action-menu-spacing: 9px;
  --action-menu-button-size: 40px;
  --action-menu-button-icon-size: 26px;
  --action-card-margin: 10px;
  --text-size-action-main-headline: 28px;
  --text-size-action-card-headline: 24px;
  --text-size-action-card-sub-headline: 16px;
  --vertical-center-offset: 64px;

  --icon-default-width-in-px: 30px;
  --icon-default-height-in-px: 30px;
  --default-icon-width: 32px;
  --default-round-corner-radius: 27px;

  --box-shadow-dialog-default: 0 0 8px 0 rgba(0, 0, 0, 0.2);

  --color-white: #ffffff;
  --color-tara: #d3f2dc;
  --color-oxley: #6fa27f;
  --color-green: #32aa5a;
  --color-yellow: #ffc30f;
  --color-red: #eb413c;
  --color-blue: #1464f0;
  --color-dark-grey: #424242;
  --color-black: #000;

  @include for-size(phone) {
    --toolbar-height: 56px;
    --toolbar-low-height: 26px;
    --toolbar-tabs-height: 56px;
  }
}

body {
  margin: 0;
  color: var(--text-color);
  font-family: Inter, "Helvetica Neue", sans-serif;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  text-decoration: none;
}

.item-wrapper a {
  display: block;
}

.selectable {
  -webkit-touch-callout: initial;
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

hr {
  border-color: rgba(255, 255, 255, .3);
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.gap-1 {
  gap: 1rem;
}

.gap-05 {
  gap: 0.5rem;
}

.gap-025 {
  gap: 0.25rem;
}

.cursor-pointer {
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.action-menu-icon {
  width: var(--action-menu-button-icon-size) !important;
  height: var(--action-menu-button-icon-size) !important;
  background-size: var(--action-menu-button-icon-size) var(--action-menu-button-icon-size) !important;

  svg circle {
    fill: var(--color-white) !important;
  }
}

.split-file-name {
  display: flex !important;
  width: 100%;
  overflow: hidden;

  span {
    &:first-child {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.hide {
  display: none !important;
}

#import-dialog, #status-dialog {
  width: 90vw;
  max-height: 90vh;
  max-width: 400px;
  height: 640px;
  pointer-events: auto;
}

.drag-over {
  opacity: 0.6;
  background: var(--color-accent);

  &.drag-over-denied {
    position: relative;
    opacity: 1;
    background: var(--background-color-action-card-transparent);

    & > * {
      opacity: 0.2;
    }

    &::after {
      position: absolute;
      width: 28px;
      height: 28px;
      text-align: center;
      border-radius: 50%;
      border: 1px solid transparent;
      box-shadow: 2px 2px 2px var(--login-shadow-color);
      left: 50%;
      top: 50%;
      z-index: 1000;
      transform: translate(-16px, -16px);
      display: block;
      background: url("assets/images/icons/permission_denied.svg") no-repeat center center;
      content: "";
      background-size: 38px 38px;
    }
  }
}

.no-box-shadow {
  box-shadow: none !important;
}

.bg-white {
  background-color: var(--color-white) !important;
}

.bg-blue {
  background-color: var(--color-blue);
}

.color-red {
  color: var(--color-red);
}

.color-yellow {
  color: var(--color-yellow);
}

.color-green {
  color: var(--color-green);
}

.color-blue {
  color: var(--color-blue);
}

.color-grey {
  color: var(--text-color-grey);
}

.color-white {
  color: white;
}

.text-center {
  text-align: center;
}

.toolbar-icon {
  padding: 2px;
}

.toolbar-icon, .toolbar-icon .mat-icon {
  width: 26px;
  height: 26px;
}

.round-corners {
  border-radius: var(--default-round-corner-radius) !important;
}

.headline {
  font-weight: normal;
  font-size: var(--text-size-action-card-headline);
  color: var(--text-color-action-card-shade);
  padding-top: var(--default-content-padding);
  padding-bottom: calc(var(--default-content-padding) / 1.5);
  text-overflow: ellipsis;
  overflow: hidden;

  &:nth-child(1) {
    padding-top: 0;
  }
}

.no-documents-found {
  height: 100%;
}

.right-section-content app-beginners-help,
.left-section-content app-beginners-help {
  margin-top: calc(var(--toolbar-tabs-height) + 1px);

  &.sidebar-has-toolbar-tabs {
    margin-top: 0;
  }
}

.toast-content-button {
  span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.overflow-scroll-behavior {
  overflow: hidden;
  -webkit-overflow-scrolling: auto;
}

.dark-theme .dark-theme-white svg {
  color: var(--color-white) !important;
  fill: var(--color-white) !important;
}

.dark-theme .dark-theme-invert-image {
  filter: filter-invert();
}

app-floating-action-button {
  app-action-item-group {
    margin-top: 17px;

    &::before {
      position: relative;
      top: -25px;
      display: block;
      width: 100%;
      height: 1px;
      content: " ";
      overflow: hidden;
      background: #e3e3e3;
    }

    &.hide-action-item-group,
    &:first-child {
      margin-top: 0;

      &::before {
        display: none;
      }
    }
  }
}

.object-icon-left {
  &.has-object-icon-left {
    width: 44px;
  }
}

.action-card {
  .base-list-wrapper {
    border: 1px solid var(--action-card-list-border-color);
  }
}

@import 'app/styles/tutorial';

/**
 * UTILITIES
 * @TODO: Better put all utilities in a separate file
 */

.toolbar-border-left {
  border-left: 1px solid var(--toolbar-border-color);
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.display-grid {
  display: grid;
}

[appClick] img {
  pointer-events: none;
}

.flip-horizontal {
  transform: rotateY(180deg);
}

.flip-vertical {
  transform: rotateX(180deg);
}

.flip {
  transform: rotateX(180deg) rotateY(180deg);
}

.tablet-toolbar {
  .tablet-toolbar-row {
    color: var(--toolbar-text-color);
    background-color: var(--toolbar-background-color);
    font-size: 28px;

    & > a {
      display: flex;
    }

    & > div {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    span {
      max-width: 100%;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
}

@include for-size(tablet-portrait) {
  .tablet-toolbar {
    &.small-toolbar {
      background-color: var(--toolbar-background-color);
      min-height: var(--toolbar-low-height) !important;

      .tablet-toolbar-row {
        line-height: var(--toolbar-low-height);
        height: var(--toolbar-low-height) !important;
        font-size: 14px;
      }
    }
  }
}

app-toolbar,
app-toolbar-tablet {
  app-document-list-state-icons {
    height: 32px !important;

    .icons {
      .loader {
        width: 32px !important;
        height: 32px !important;
      }

      .anchor, .attachment {
        width: 28px !important;
        height: 28px !important;
      }

      .edit-status {
        width: 30px !important;
        height: 30px !important;

        mat-icon {
          width: 20px !important;
          height: 20px !important;
        }
      }

      .version {
        width: 30px !important;
        height: 30px !important;
        font-size: 12px !important;
        line-height: 30px !important;
      }
    }

    @include for-size(tablet-portrait) {
      height: 32px !important;

      .icons {
        .loader {
          width: 20px !important;
          height: 20px !important;
        }

        .anchor, .attachment {
          width: 16px !important;
          height: 16px !important;
        }

        .edit-status {
          width: 18px !important;
          height: 18px !important;

          mat-icon {
            width: 10px !important;
            height: 10px !important;
          }
        }

        .version {
          width: 18px !important;
          height: 18px !important;
          font-size: 10px !important;
          line-height: 18px !important;
        }
      }
    }
  }
}
